<template>
  <div>
    <baidu-map-view
      :map-data="{
        data: [
          { lng: 121.143326, lat: 30.028305 },
          { lng: 121.144759, lat: 30.0287 },
        ],
        mapType: 3,
        showButton: true,
        //width: mapWidth,
        //height: mapHeight,
      }"
      @confirmEvent="baiduMapCallback"
    />
  </div>
</template>

<script>
  import BaiduMapView from '@/components/BaiduMapView.vue'

  export default {
    name: 'Index',
    components: { BaiduMapView },
    data() {
      return {
        info: '',
        mapWidth: '50%',
        mapHeight: '500px',
      }
    },
    methods: {
      baiduMapCallback(data) {
        console.log('data', data)
      },
    },
  }
</script>

<style scoped></style>
